import React from "react";
import { GetStaticProps, GetStaticPropsResult, NextPage } from "next";

import { SeoPageProps } from "@libs";
import { fetchElearnings, FetchElearningsResponse } from "@api";
import { getCounterFromCategoriesList, getTopCategoriesFrom } from "@utils";
import { Elearnings } from "@app-components";
import messages from "@pages_messages/elearnings/messages";

import withHeader from "hocs/withHeader";
import Footer from "containers/Footer";

type ElearningsStaticProps = {
	topTenSchools: [string, number][];
};

const ElearningsPage: NextPage<ElearningsStaticProps> = ({ topTenSchools }: ElearningsStaticProps) => {
	return (
		<>
			<Elearnings topTenSchools={topTenSchools} />
			<div className="relative bg-white">
				<Footer />
			</div>
		</>
	);
};

export const getStaticProps: GetStaticProps<SeoPageProps & ElearningsStaticProps> = async (): Promise<
	GetStaticPropsResult<SeoPageProps & ElearningsStaticProps>
> => {
	const { items } = await fetchElearnings({
		page: 1,
		perPage: 9999999,
		orderDirection: "DESC",
	})();
	const schoolsCounter = getSchoolsCounter({
		items,
	});
	const topTenSchools = getTopCategoriesFrom(schoolsCounter, 10);

	return {
		props: {
			topTenSchools,
			meta: {
				title: String(messages.title.id),
				description: String(messages.description.id),
			},
			robots: {
				index: true,
			},
		},
	};
};

const getSchoolsCounter = (elearnings: Pick<FetchElearningsResponse, "items">): Record<string, number> => {
	if (!elearnings) {
		return {};
	}

	const elearningsSchools = elearnings.items.map((elearning) => elearning.schools);
	const schoolsCounter: Record<string, number> = {};

	elearningsSchools.forEach((schools) => getCounterFromCategoriesList(schools, schoolsCounter));

	return schoolsCounter;
};

export default withHeader(ElearningsPage);
